<script>
import { msalInstance } from "@/authConfig";
import { BrowserUtils } from "@azure/msal-browser";
import { onMounted } from "vue";
export default {
  setup() {
    const idTokenHint = localStorage.getItem("tokenAdb2c");
    onMounted(async () => {
      console.log("Logout da management");
      console.log("localStorageManagement", localStorage);
      msalInstance.logoutRedirect({
        idTokenHint: idTokenHint,
        onRedirectNavigate: () => {
          localStorage.clear();
          // retrieve all cookies
          var Cookies = document.cookie.split(";");
          // set past expiry to all cookies
          for (var i = 0; i < Cookies.length; i++) {
            document.cookie =
              Cookies[i] + "=; expires=" + new Date(0).toUTCString();
          }
          console.log("Dopo Clear", localStorage);
          return false;
        },
      });
    });
  },
};
</script>
